body,
html,
#root {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #181a1b;
  color: #d1cdc7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
}

.card-text {
  font-size: 14px;
}

.card {
  background-color: rgb(24, 26, 27);
  border-color: rgba(140, 130, 115, 0.13);
  color: #c8c3bc;
}

.bg-dark {
  background-color: #1c1e1f !important;
}

.card .text-muted {
  width: 250px;
}

/* just for demo purposes */
.btn {
  width: 175px;
}

.list-group-item {
  color: #ebebeb !important;
  background-color: #101010 !important;
}

.small-btn {
  width: 110px !important;
}
